import React from "react";
import BookDemoButton from "../buttons/BookDemoButton";

const Welcome = () => {
  return (
    <section
      style={{
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      className="w-full flex justify-center relative"
    >
      <div className="max-w-screen-xl w-full h-full flex flex-col md:items-center px-4 md:px-4 z-20 py-20 pt-12 md:pt-20 2xl:pt-24">
        <header className="w-full flex flex-col items-center text-gray-800 py-6">
          <h1 className="font-semibold text-4xl md:text-[3rem] 2xl:text-[3.2rem] leading-snug tracking-[-0.01em] text-center">
            Forward your email,
            <br />
            <span className="text-primary">Receive a spreadsheet.</span>
          </h1>
          <h2 className="mt-8 md:mt-10 text-sm max-w-xl text-gray-500 leading-relaxed text-center">
            Upload your existing spreadsheet, forward your email, and watch as the data is automatically filled
            in—saving you time and eliminating manual entry.
          </h2>
          <div className="flex flex-row items-center space-x-3 md:space-x-4 mt-12 justify-center">
            <BookDemoButton />
            <button className="flex items-center justify-center space-x-3 h-9 px-3 md:px-5 rounded-full text-sm md:text-base border transition font-medium bg-white hover:bg-gray-100 text-gray-700">
              See how it works
            </button>
          </div>
        </header>

        <section className="w-full mt-20 flex flex-col items-center justify-center text-gray-700">
          <div className="w-full h-auto md:w-auto rounded-2xl overflow-hidden bg-gray-200 p-1.5 md:p-3">
            <img
              className="rounded-xl"
              src="https://s3.mailtocell.com/landing/landing3.png"
              alt="Mailtocell email-to-spreadsheet automation tool demonstration"
              width="100%"
              height="100%"
            />
          </div>
        </section>
      </div>
    </section>
  );
};

export default Welcome;
