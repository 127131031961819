import "./App.css";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import SEOMetaTag from "./utils/SEOMetaTag";
import ScrollToTop from "./utils/scrollToTop";
import { AxiosInterceptor } from "./utils/authAxios";
import Landing from "./pages/Landing";
import Auth from "./pages/Auth/Auth";
import Legal from "./pages/Legal/Legal";
import NavBar from "./components/NavBar";
import Main from "./pages/Main/Main";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Pricing from "./pages/Pricing";

function App() {
  const { userState } = useContext(AuthContext);
  
  if (userState.isAuthenticated)
    return (
      <AxiosInterceptor>
        <div style={{ height: "100svh" }} className="flex flex-col items-center w-full overflow-x-hidden">
          <ScrollToTop />
          <SEOMetaTag
            title="Mailtocell: Automate Email to Spreadsheet Conversion"
            description="Mailtocell automates email-to-spreadsheet conversion, saving you time and eliminating manual data entry. Effortless data management and analysis."
            keywords="email to spreadsheet automation, email data extraction, email forwarding to spreadsheet, Mailtocell, document automation, data extraction API, spreadsheet automation, email processing tool"
          />
          <Routes>
            <Route path="/*" element={<Main />} />
          </Routes>
        </div>
      </AxiosInterceptor>
    );
  else
    return (
      <AxiosInterceptor>
        <NavBar />
        <div className="flex flex-col items-center w-full overflow-x-hidden text-gray-800">
          <ScrollToTop />
          <SEOMetaTag
            title="Mailtocell: Automate Email to Spreadsheet Conversion"
            description="Mailtocell automates email-to-spreadsheet conversion, saving you time and eliminating manual data entry. Effortless data management and analysis."
            keywords="email to spreadsheet automation, email data extraction, email forwarding to spreadsheet, Mailtocell, document automation, data extraction API, spreadsheet automation, email processing tool"
          />
          <Routes>
            <Route path="/*" element={<Landing />} />
            <Route path="/about/*" element={<AboutUs />} />
            <Route path="/pricing/*" element={<Pricing />} />
            <Route path="/contact/*" element={<ContactUs />} />
            <Route path="/legal/*" element={<Legal />} />
            <Route path="/auth/*" element={userState.isAuthenticated ? <Navigate to="/" /> : <Auth />} />
          </Routes>
        </div>
      </AxiosInterceptor>
    );
}

export default App;
