import React from "react";
import { Link } from "react-router-dom";
import { VscThreeBars } from "react-icons/vsc";
import { Dropdown } from "antd";
import BookDemoButton from "./buttons/BookDemoButton";

const NavBar = () => {
  const items = [
    {
      key: "1",
      label: (
        <Link to={"/about"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">About</button>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={"/pricing"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">Pricing</button>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to={"/contact"}>
          <button className="text-sm hover:text-blue-500 transition h-14 w-48">Contact</button>
        </Link>
      ),
    },
  ];

  const NavButton = ({ path, title, newTab }) => {
    if (path)
      return (
        <Link
          to={path}
          target={newTab && "_blank"}
          rel={newTab && "noopener noreferrer"}
          className="text-gray-700 text-sm hidden md:flex items-center hover:bg-gray-100 px-3.5 h-8 rounded-full group font-WantedSans transition font-medium"
        >
          <button>{title}</button>
        </Link>
      );
    else
      return (
        <button className="text-gray-700 text-sm hidden md:flex items-center hover:bg-gray-100 px-3.5 h-8 rounded-full group font-WantedSans transition font-medium">
          {title}
        </button>
      );
  };

  return (
    <header className="w-full flex-shrink-0 flex items-center justify-center z-30 fixed top-0 h-20 p-2 bg-white">
      <div className="flex items-center w-full max-w-screen-xl h-full justify-between rounded-full px-5 lg:px-10 py-4 lg:py-0">
        <Link to="/">
          <div className="flex items-center flex-col">
            <img
              src={"https://s3.mailtocell.com/landing/mailtocell_logo.png"}
              alt=""
              className="h-10 w-32 object-contain"
              draggable={false}
            />
            <p className="text-xs font-medium -mt-0.5 text-gray-500">by TradDocs</p>
          </div>
        </Link>
        <div className="flex flex-shrink-0 items-center text-gray-700">
          <div className="flex items-center space-x-4">
            <NavButton path={"/about"} title={"About"} />
            <NavButton path={"/pricing"} title={"Pricing"} />
            <NavButton path={"/contact"} title={"Contact"} />
            <Link className="flex-shrink-0" to={"/auth/login"}>
              <button className="text-sm text-gray-700 h-8 px-3 hover:bg-gray-100 rounded-full transition flex-shrink-0 font-medium">
                Login
              </button>
            </Link>
            <div className="hidden md:flex space-x-2 items-center">
              <BookDemoButton />
            </div>
            <>
              <div className="md:hidden flex">
                <Dropdown menu={{ items }} placement="bottomRight">
                  <button className="group md:hidden flex md:w-0 w-14 h-14 text-sm items-center justify-center">
                    <VscThreeBars className="w-6 h-6 text-gray-700" />
                  </button>
                </Dropdown>
              </div>
            </>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
