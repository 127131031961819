import React from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import BookDemoButton from "../buttons/BookDemoButton";

const CheckScopeIntro = () => {
  const panelStyle = {
    marginBottom: 0,
    // background: "#d1d5db",
    border: "none",
  };

  const FeaturesArray = [
    {
      question: "Instant Data Integration",
      answer:
        "Simply forward your emails, and Mailtocell will automatically populate your existing spreadsheet with relevant data.",
    },
    {
      question: "Supports Various Document Types",
      answer:
        "Handles diverse types of emails, including attachments and inline content, ensuring comprehensive data extraction.",
    },
    {
      question: "No Manual Entry Required",
      answer:
        "Eliminates the need for manual data entry by automatically organizing email content into your spreadsheet.",
    },
  ];

  const FeaturesArray2 = [
    {
      question: "Easy Setup",
      answer: "Upload your spreadsheet template and forward emails to start—no complex configurations required.",
    },
    {
      question: "Secure and Private",
      answer:
        "Mailtocell ensures your data is processed securely, with robust privacy measures to protect your information.",
    },
    {
      question: "Seamless Integration",
      answer:
        "Easily integrates with your existing tools and workflows, streamlining data management across platforms.",
    },
  ];

  const getFeaturesItems = (array, panelStyle) =>
    array.map((item, index) => ({
      key: `${index + 1}`,
      label: <p className="font-WantedSans font-medium text-base hover:text-blue-500 transition">{item.question}</p>,
      children: <p className="font-WantedSans text-blue-900 p-4 bg-blue-50 rounded text-sm">{item.answer}</p>,
      style: panelStyle,
    }));

  return (
    <div className="flex w-full items-center justify-center flex-col md:pt-24 py-12 text-white px-4">
      <div className="max-w-screen-xl w-full h-full flex flex-col md:items-center px-4 md:px-4 z-20 py-16 bg-gradient-to-b from-gray-600 to-gray-800 rounded-2xl">
        <h3 style={{ whiteSpace: "pre-wrap" }} className="text-3xl font-semibold text-center leading-normal">
          {`Streamline Your Workflow,\nWith Automated Data Integration`}
        </h3>
        <h4 className="mt-4 mb-8 break-keep text-sm max-w-lg text-gray-400 leading-relaxed text-center w-full">
          Discover how Mailtocell simplifies data management by effortlessly converting email content into organized
          spreadsheet entries.
        </h4>
        <div className="mb-12">
          <BookDemoButton mode="dark" />
        </div>
        <div className="max-w-3xl w-full flex flex-col items-center pb-12">
          <div className="w-full flex flex-col md:flex-row md:justify-center">
            <div className="w-full grid grid-cols-2 rounded-xl overflow-hidden">
              <Collapse
                bordered={false}
                size="middle"
                expandIcon={({ isActive }) => <CaretRightOutlined style={{ color: "" }} rotate={isActive ? 90 : 0} />}
                style={{
                  background: "transparent",
                }}
                items={getFeaturesItems(FeaturesArray, panelStyle)}
                className="custom-collapse" // Add this class
                accordion
              />
              <Collapse
                bordered={false}
                size="middle"
                expandIcon={({ isActive }) => <CaretRightOutlined style={{ color: "" }} rotate={isActive ? 90 : 0} />}
                items={getFeaturesItems(FeaturesArray2, panelStyle)}
                className="custom-collapse" // Add this class
                accordion
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckScopeIntro;
