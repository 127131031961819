import React from "react";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

const FAQ = () => {
  const FAQArray = [
    {
      question: "How do I start using Mailtocell?",
      answer:
        "You can start using Mailtocell for free by signing up on our website. No credit card is required to get started, and you’ll receive 15 free email forwards each month.",
    },
    {
      question: "What types of documents can Mailtocell handle?",
      answer:
        "Mailtocell supports a variety of document types including PDFs, spreadsheets, and text files. It also works with different image formats like JPG, JPEG, and PNG, making it versatile for handling most common document types.",
    },
    {
      question: "Do I need to pre-train or label data before using Mailtocell?",
      answer:
        "No, Mailtocell is designed to work out-of-the-box. You don’t need to pre-train or label data; simply forward your emails and Mailtocell will handle the rest.",
    },
    {
      question: "Can Mailtocell extract tables from documents?",
      answer:
        "Yes, Mailtocell can extract tables from documents, including spreadsheets and PDFs. It also handles various data types such as text, numbers, and dates, providing comprehensive data extraction capabilities.",
    },
    {
      question: "Can Mailtocell extract images from documents?",
      answer:
        "Yes, Mailtocell can extract images embedded in documents like PDFs. This feature allows you to retrieve and utilize images for further analysis or integration into other applications.",
    },
    {
      question: "Can Mailtocell handle multiple document types in a single email forward?",
      answer:
        "Yes, Mailtocell can process multiple document types sent in a single email forward. It efficiently extracts data from various formats, streamlining your document handling process.",
    },
    {
      question: "How reliable is Mailtocell?",
      answer:
        "Mailtocell is highly reliable and accurate, designed to meet professional standards. Its robust performance ensures that your data extraction needs are handled with precision and efficiency.",
    },
    {
      question: "What are the pricing plans for Mailtocell?",
      answer:
        "Mailtocell offers a free plan with 15 email forwards per month. The Basic Plan costs $49 per month and includes additional forwards. We also offer a custom pay-as-you-go option for high-volume needs, ensuring flexibility based on your usage.",
    },
    {
      question: "How do I integrate Mailtocell into my existing workflow?",
      answer:
        "After signing up, you can start using Mailtocell by forwarding your emails. For more advanced integration, you can use our API, which allows seamless incorporation into your existing systems and workflows.",
    },
  ];

  return (
    <div className="w-full flex justify-center relative bg-gray-50">
      <div className="max-w-screen-xl w-full h-full flex flex-col items-center px-4 z-20 text-center py-20">
        <h3 style={{ whiteSpace: "pre-wrap" }} className="text-4xl font-semibold text-center leading-normal">
          Frequently Asked Questions
        </h3>
        <div className="mt-4 mb-8 break-keep text-base max-w-lg text-gray-500 leading-relaxed text-center w-full">
          <p className="inline text-gray-500">Need more information?</p>
          <Link to="/contact-us">
            <p className="inline text-blue-500 ml-2">Contact us</p>
          </Link>
        </div>

        <div className="w-full space-y-2 max-w-screen-lg">
          {FAQArray.map((item, index) => (
            <Collapse
              key={index}
              size="middle"
              className="bg-white text-left text-gray-700"
              items={[
                {
                  key: index,
                  label: <p className="font-semibold">{item.question}</p>,
                  children: <p className="break-keep">{item.answer}</p>,
                },
              ]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
