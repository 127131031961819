import React, { useEffect, useState } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import NavBar2 from "../../components/NavBar2";
import LeftPanel from "../../components/Dashboard/LeftPanel";
import Dashboard from "./Dashboard";

const Main = () => {
  const navigate = useNavigate();
  const [extractorData] = useState([
    { template_name: "New template", template_id: "0" },
    { template_name: "New template2", template_id: "1" },
  ]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/") {
      navigate("/spreadsheet");
    }
  }, [navigate]);

  return (
    <div style={{ height: "100svh" }} className="w-full">
      <NavBar2 />
      <div style={{ height: "calc(100svh - 3rem)" }} className="flex w-full">
        <Routes>
          <Route
            path="/spreadsheet/*"
            element={
              <div style={{ height: "calc(100svh - 3rem)" }} className="w-full">
                <div className="w-full h-full bg-gray-100 flex px-2 pb-2">
                  <LeftPanel extractorData={extractorData} />
                  <div className="bg-white rounded-lg w-full overflow-hidden border">
                    <Dashboard />
                  </div>
                </div>
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Main;
