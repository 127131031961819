import React from "react";
import Login from "./Login";
import Footer from "../../components/Footer";

const Auth = () => {
  return (
    <div className="w-full flex flex-col items-center relative">
      <div
        style={{ minHeight: "calc(100vh - 10rem)" }}
        className="w-full flex flex-col items-center justify-center relative bg-neutral-50 flex-shrink-0 py-36"
      >
        <div className="z-20">
          <Login />
        </div>
      </div>
      <div className="z-20 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Auth;
