import React from "react";
import { Link } from "react-router-dom";
import ProfileButton from "./ProfileButton";

const NavBar2 = () => {
  return (
    <header className="w-full flex items-center justify-center z-30 h-12 bg-gray-100">
      <div className="flex items-center w-full h-full justify-between rounded-full px-3 py-4 lg:py-0">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={"https://s3.mailtocell.com/landing/mailtocell_logo.png"}
              alt=""
              className="h-7 object-contain"
              draggable={false}
            />
          </div>
        </Link>
        <div className="flex flex-shrink-0 items-center text-gray-700">
          <div className="flex items-center space-x-3">
            <ProfileButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar2;
