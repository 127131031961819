import { Modal } from "antd";
import React from "react";

const SubscriptionModal = ({ setOpen, open }) => {
  const handleCancel = () => {
    setOpen(false);
  };

  const PricingCard = ({ currentPlan, title, subtitle, featureArray }) => {
    return (
      <div className={` w-full rounded-lg border bg-white overflow-hidden shadow-lg`}>
        <div className="w-full h-52 flex flex-col justify-center items-center border-b px-4 bg-gray-50">
          <p className={`text-gray-700 font-medium text-lg`}>{title}</p>
          <p className={`text-gray-700 mt-4 text-sm opacity-70 text-center`}>{subtitle}</p>
          {currentPlan ? (
            <button className="px-4 h-9 font-medium text-gray-500 mt-4 text-xs">Current plan</button>
          ) : (
            <button className="px-4 h-9 rounded-lg font-medium shadow bg-blue-500 text-white transition hover:bg-opacity-75 mt-4 text-xs">
              Select plan
            </button>
          )}
        </div>
        <div style={{ height: "16rem" }} className="py-4 px-4 space-y-3 bg-gray-100">
          {featureArray?.map((item, index) => (
            <div key={index} className="flex items-center justify-between">
              <p className="text-gray-500 break-keep text-xs">• {item.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        title="Mailtocell pricing plans"
        onCancel={handleCancel}
        width={900}
        style={{ top: 30 }}
        className="px-4"
        footer={<></>}
      >
        <div className="flex w-full space-x-4 my-10 px-8">
          <PricingCard
            currentPlan
            title="Free Plan ($0/month)"
            subtitle="Limited usage and quota of paid services"
            featureArray={[
              { title: "15 entries a month" },
              { title: "Single file upload max 20 pages" },
              { title: "Single file upload max 40MB" },
            ]}
          />
          <PricingCard
            title="Basic Plan ($9/month)"
            subtitle="Additional quota and pages for personal projects"
            featureArray={[
              { title: "190 pages a month" },
              { title: "Single file upload max 50 pages" },
              { title: "Single file upload max 100MB" },
            ]}
          />
          <PricingCard
            title="Pay as you go"
            subtitle="Extended usage and quota of paid services"
            featureArray={[
              { title: "No limit per month" },
              { title: "Single file upload max 100 pages" },
              { title: "No limit on file size" },
            ]}
          />
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
