import React from "react";
import Footer from "../components/Footer";
import Partners from "../components/Landing/Partners";
import FAQ from "../components/Landing/FAQ";
import { Link } from "react-router-dom";
import SEOMetaTag from "../utils/SEOMetaTag";

const Pricing = () => {
  const Welcome = () => (
    <div className="w-full flex justify-center relative pt-4">
      <div className="max-w-screen-xl w-full h-full flex items-center px-4 md:px-16 z-20 text-center md:pb-16 pb-4">
        <div className="w-full flex flex-col justify-center items-center text-gray-700 break-keep">
          <h1 className="font-semibold text-4xl md:text-[3rem] 2xl:text-[3rem] leading-tight">
            Start Free, Pay As You Grow
          </h1>
          <h2 className="mt-8 md:mt-10 break-keep text-sm text-gray-500 leading-relaxed">
            Get 15 free email forwards each month. Only pay for what you use beyond that.
          </h2>
        </div>
      </div>
    </div>
  );

  const PricingCard = ({ type, title, subtitle, price, featureArray, isRecommended }) => {
    return (
      <div
        className={`${
          isRecommended ? "bg-blue-500 shadow-xl" : "bg-white bg-opacity-0"
        } p-2 rounded-lg space-y-2 flex flex-col items-center w-full`}
      >
        <p className="text-white font-medium text-sm h-5">{isRecommended ? "RECOMMENDED" : ""}</p>
        <div className={`${!isRecommended && "shadow-xl"} w-full rounded-lg border bg-white overflow-hidden`}>
          <div
            style={{ backgroundColor: "#F2F6F8" }}
            className="w-full h-52 flex flex-col justify-center items-center border-b px-4"
          >
            <p className={`text-gray-700 font-semibold text-3xl`}>{title}</p>
            <p className={`text-gray-700 mt-4 text-sm opacity-70 text-center`}>{subtitle}</p>
            {type === "custom" ? (
              <></>
            ) : (
              <div className={`text-gray-700 flex mt-4 items-center space-x-2`}>
                <div className="flex items-end space-x-1">
                  <span className={`text-4xl`}>{price}</span>
                  <span className="text-xl font-medium">USD</span>
                </div>
                <span className="text text-gray-400 font-normal">per month</span>
              </div>
            )}
          </div>
          <div style={{ height: "23rem" }} className="py-8 px-6 space-y-6">
            <p className="text-gray-500 py-1 text-sm">
              Enjoy 15 free email forwards each month. Pay-as-you-go for additional usage.
            </p>
            {featureArray?.map((item, index) => (
              <div key={index} className="flex items-center justify-between">
                <div>
                  <p className="text-gray-500 break-keep font-medium text-sm">{item.title}</p>
                  <p className="text-gray-500 break-keep text-xs">{item.subtitle}</p>
                </div>
                <p className="text-gray-500 break-keep text-sm">{item.value}</p>
              </div>
            ))}
          </div>

          <div className="w-full flex flex-col justify-end px-4 pb-4">
            <div className="h-14 w-full border rounded-lg overflow-hidden">
              {type === "basic" ? (
                <Link to={"/auth/signup"}>
                  <button className={`w-full h-full font-medium shadow bg-gray-700 text-white hover:bg-opacity-75`}>
                    Start for Free
                  </button>
                </Link>
              ) : (
                <Link to={"/auth/signup"}>
                  <button className={`w-full h-full font-medium shadow bg-white text-gray-700 hover:bg-opacity-75`}>
                    Start for Free
                  </button>
                </Link>
              )}
            </div>
            <div className="w-full flex justify-center">
              <p className="mt-4 text-xs text-gray-500">No credit card required</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex items-center flex-col relative pt-20 bg-neutral-50">
      <SEOMetaTag
        title="Pricing - Mailtocell"
        description="Explore Mailtocell's pricing plans, including a free plan, basic plan, and custom pay-as-you-go option. Find the best solution for your email-to-spreadsheet automation needs and start saving time today."
        keywords="Mailtocell pricing, email-to-spreadsheet automation pricing, data automation plans, Mailtocell subscription, flexible pricing plans"
        url="https://www.mailtocell.com/pricing"
      />

      <div className="w-full z-20 flex flex-col items-center">
        <div className="max-w-screen-xl w-full h-full flex flex-col md:items-center px-4 md:px-4 z-20 py-20 pt-12 md:pt-20 2xl:pt-24">
          <Welcome />

          <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-3 w-full mb-16">
            <PricingCard
              type="free"
              title="Free Plan"
              subtitle="Start with 15 free email forwards per month and see how Mailtocell can streamline your workflow."
              price={"$0"}
              featureArray={[{ title: "15 forwards per month", value: "Free" }]}
            />
            <PricingCard
              type="basic"
              title="Basic Plan"
              subtitle="Get additional forwards and enhanced features to suit more advanced needs."
              price={"$49"}
              featureArray={[
                { title: "15 forwards per month", value: "Free" },
                { title: "Additional 50 forwards per month", value: "$49 / month" },
              ]}
              isRecommended
            />
            <PricingCard
              type="custom"
              title="Pay-As-You-Go"
              subtitle="Flexible pricing for high-volume users with a customized plan to match your needs."
              price={"Custom"}
              featureArray={[
                { title: "Additional Email Forwards", value: "$0.75 / forward" },
                { title: "High-Volume Discounts", value: "Contact us for pricing" },
              ]}
            />
          </div>
          <Partners />
          <FAQ />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Pricing;
