import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import {AuthProvider} from "./context/authContext";
import {ConfigProvider} from "antd";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <AuthProvider>
                <GoogleOAuthProvider
                    clientId="506419402946-tkk8pjij6lsok9tq5km4kgu8cmlapp5n.apps.googleusercontent.com">
                    <BrowserRouter>
                        <ConfigProvider>
                            <App/>
                        </ConfigProvider>
                    </BrowserRouter>
                </GoogleOAuthProvider>
            </AuthProvider>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
