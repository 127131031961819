import React, {useCallback, useEffect, useRef, useState} from "react";
import "@glideapps/glide-data-grid/dist/index.css";
import DataEditor from "@glideapps/glide-data-grid";
import axios from "../../utils/authAxios";
import {useUndoRedo} from "../../utils/undoRedo";

const Spreadsheet = ({extractedData}) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [columns, setColumns] = useState([])
    const [showSearch, setShowSearch] = useState(false)
    const gridRef = useRef(null);

    useEffect(() => {
        setColumns(extractedData.map(t => (t.columns?.map(c => ({...c, id: `${t.sheet_id}-${c.key}`})))))
    }, [extractedData]);

    const getData = useCallback(([col, row]) => {
        const rowData = extractedData[currentTab]?.rows[row];
        const key = columns[currentTab][col]?.key
        const cellData = rowData?.[key] || ''
        return {
            kind: 'text',
            allowOverlay: true,
            data: cellData,
            displayData: cellData
        }
    }, [extractedData, currentTab, columns])

    const onColumnResize = useCallback((column, newSize) => {
        setColumns(prevState => {
            const index = prevState[currentTab].findIndex(ci => ci.title === column.title)
            const currentColumn = [...prevState[currentTab]]
            currentColumn[index].width = newSize
            prevState.splice(currentTab, 1, currentColumn)
            return [...prevState]
        })
    }, [currentTab]);

    const eventDataDefault = (obj = {}) => {
        return new Proxy(obj, {
            get: (target, key) => {
                if (!(key in target)) target[key] = {event_type: 'update', event_data: {}};
                return target[key];
            }
        });
    }

    const onCellsEditedHandler = useCallback((newValues) => {
        const eventData = eventDataDefault()
        newValues.forEach(newValue => {
            const [col, row] = newValue.location
            const value = newValue.value
            const currentColumn = columns[currentTab][col]
            if (currentColumn.key) {
                const currentRow = extractedData[currentTab].rows[row]
                currentRow[currentColumn.key] = value.data
                eventData[currentRow['key']].event_data[currentColumn.key] = value.data
            }
        })
        const events = Object.entries(eventData).map(([key, value]) => ({event_key: key, ...value}))
        events.length && axios.put('/v1/events', events).then()
    }, [extractedData, currentTab, columns]);

    const {gridSelection, onCellsEdited, onGridSelectionChange} = useUndoRedo(
        gridRef,
        getData,
        onCellsEditedHandler
    );

    const searchShortCut = useCallback(event => {
        if ((event.ctrlKey || event.metaKey) && event.code === "KeyF") {
            event.preventDefault();
            event.stopPropagation();
            setShowSearch(cv => !cv);
        }
    }, [setShowSearch])

    useEffect(() => {
        const onKeyDown = (event) => {
            if (event.code === "KeyF" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault();
                event.stopPropagation();
                setShowSearch(cv => !cv);
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown)
        };
    }, [searchShortCut]);

    return (
        <div>
            <div className="flex space-x-3 mb-3">
                {extractedData.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentTab(index)}
                        className={`${
                            currentTab === index ? "font-medium bg-gray-200 text-blue-500" : "bg-gray-100 text-gray-600"
                        } px-4 h-9 rounded-lg text-sm`}>
                        {item['sheet_name']}
                    </button>
                ))}
            </div>
            <div className="w-full h-full overflow-auto text-gray-700 space-y-8 pb-2">
                <div className="text-xs font-WantedSans"
                     style={{height: "calc(100svh - 20rem)", minHeight: "500px"}}>
                    <DataEditor ref={gridRef}
                                columns={columns[currentTab] ?? []}
                                getCellContent={getData}
                                rows={extractedData[currentTab]?.rows.length}
                                rowMarkers={'number'}
                                rowHeight={24}
                                headerHeight={26}
                                getCellsForSelection={true}
                                width={'100%'}
                                height={'100%'}
                                onPaste={false}
                                showSearch={showSearch}
                                onSearchClose={() => setShowSearch(false)}
                                gridSelection={gridSelection ?? undefined}
                                onGridSelectionChange={onGridSelectionChange}
                                onCellsEdited={onCellsEdited}
                                onColumnResize={onColumnResize}/>
                    <div id="portal"/>
                </div>
            </div>
        </div>
    )
}

export default Spreadsheet;
