import React from "react";
import { FaLinkedin, FaMapPin } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaDiscord } from "react-icons/fa6";

const Footer = () => {
  const exploreList = [
    { title: "About", url: "/about" },
    { title: "Pricing", url: "/pricing" },
    { title: "Contact", url: "/contact" },
    { title: "Terms of Service", url: "/legal/terms" },
    { title: "Privacy Policy", url: "/legal/privacy" },
  ];

  return (
    <>
      <div className="w-full flex-shrink-0 flex flex-col items-center justify-center bg-gray-800 px-4">
        <div className="space-y-4 h-24 flex items-center w-full max-w-screen-xl border-b border-gray-600">
          <div className="flex flex-col items-center">
            <img
              src={"https://s3.mailtocell.com/landing/mailtocell_logo_white.png"}
              alt=""
              className="h-9 w-32 object-contain"
              draggable={false}
            />
            <p className="text-xs font-medium text-gray-400">by TradDocs</p>
          </div>
        </div>
        <div
          style={{ minHeight: "24rem" }}
          className="max-w-screen-xl px-4 flex flex-col md:flex-row w-full h-full md:space-x-16 py-8"
        >
          <div
            style={{ minWidth: "16rem" }}
            className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 flex-shrink-0"
          >
            <p className="text-gray-200 text-lg font-semibold mb-8">EXPLORE</p>
            <div className="space-y-4 flex flex-col">
              {exploreList.map((item) => (
                <Link key={item.title} to={item.url}>
                  <button>
                    <div className="text-sm hover:text-white transition">{item.title}</div>
                  </button>
                </Link>
              ))}
            </div>
          </div>
          <div className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 flex-shrink-0">
            <p className="text-gray-200 text-lg font-semibold mb-8">CONTACT</p>

            <div className="flex space-x-3 mb-4">
              <FaMapPin className="mt-1" />
              <div>
                <p className="">122 Mapo-daero, Mapo-gu, Seoul, South Korea</p>
                <p className="">04213</p>
              </div>
            </div>
            <div className="flex space-x-3 mb-4">
              <IoMdMail className=" mt-1" />
              <div>
                <p className="">johnk@traddocs.com</p>
              </div>
            </div>
            <div className="flex space-x-3">
              <Link to="https://www.linkedin.com/company/mailtocell">
                <FaLinkedin className="w-8 h-8 text-white" />
              </Link>
              <Link to="https://discord.gg/xgEXkh7Rxk">
                <FaDiscord className="w-8 h-8 text-white" />
              </Link>
            </div>
          </div>
          <div className="text-sm text-gray-400 flex flex-col md:mt-0 mt-12 justify-end w-full items-end">
            <img
              draggable={false}
              src={"https://s3.mailtocell.com/landing/ms_dark.png"}
              alt=""
              className="w-48"
            />
          </div>
        </div>

        <div className="max-w-screen-xl w-full h-24 border-t border-gray-600 flex items-center justify-between text-gray-200 text-xs">
          <p className="">© {new Date().getFullYear()} TradDocs Inc. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
